import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Introduction from "../../components/introduction"
import HeroAlt from "../../components/hero-alt"
import Spacer from "../../components/spacer"
import TitleAndText from "../../components/title-and-text"
import ImageAndText from "../../components/image-and-text"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import CenteredSlider from "../../components/centered-slider"
import CenteredSlide from "../../components/centered-slide"
import WorkGrid from "../../components/work-grid"
import ImageGridItemService from "../../components/image-grid-item-service"
import ContactForm from "../../components/contact-form"
import ImageGridItem from "../../components/image-grid-item"


class WebOptimisationPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Website Security Bournemouth" />
        <HeroAlt 
          image="/speed.jpg"
          title="Website Optimisation"
          subtitle="Having a responsive and fast website is essential these days. Old Salt can help to optimise your website to be lightning fast."
        />
        
        <Spacer space="4" />    

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Is your website slow?</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Is your website slow? Is it failing to provide a useful service and ‘shopfront’ for your business? Not only can this be frustrating for your customers but it could also be having negative effects on your search rankings and visibility on search tools like Google.</p>
                <p>We can provide a site audit for you with benchmarks of how well your website is performing and also suggest ways that your website can be optimised to load lightning fast.</p>
                <Spacer space="2" />    
                <Link to="/contact" className="button">Optimise your website</Link>
                <Spacer space="2" />  
              </div>              
            </div>
          </div>
        </div>

        <Spacer space="2" />

        <img src="/speed-2.jpg" width="100%" />

        <Spacer space="4" />

        <div className="row shrink-width align-center">

          <div className="column small-12 large-4">
            <h2 className="gm-bottom--1" style={{ lineHeight: 1 }}>Core Web Vitals</h2>  
          </div>
          <div className="column small-12 large-8">
            <div className="row align-center">
              <div className="column small-12 large-10">
                <p>Core Web Vitals are a specific set of factors that Google considers vital to your webpage’s overall user experience. It’s a measure that Google has implemented to measure how your website stacks up for user experience. Generally speaking this is determined by how quickly your website loads for users and how accessible it is.</p>
                <p>This measurement is now officially influencing your search rankings. Your website’s speed and accessibility will actually start affecting your rank in search results.</p>
                <p>We can review your website’s current core web vitals and help you to improve them so you retain or improve your search ranking.</p>
              </div>              
            </div>
          </div>
        </div>

        <div className="row shrink-width align-center">
          <div className="column small-12 gm-top--1">
            <img src="/cwv.jpg" alt="Core Web Vitals" />
          </div>
        </div>

        <Spacer space="2" />

        <div className="gm-top--2 gm-bottom--2 contact-form--pullout">
          <div className="clearfix"></div>
          <div className="row align-center gm-top--4 gm-bottom--4">
            <div className="column small-12 medium-10 large-8">
              <h3>Get up to speed</h3>
              <p>Get in touch to find out how we can help you optimise your website.</p>
              <Spacer space="2" />
              <ContactForm pageName="website-optimisation" />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>

        <WorkGrid>
          <ImageGridItemService width={6} image={ '/web-development.jpg' } subtitle="Development" title="Website Development" link="/services/web-development-bournemouth" />
          <ImageGridItemService width={6} image={ '/maintenance.jpg' } subtitle="Development" title="WordPress Maintenance" link="/services/wordpress-maintenance" />
        </WorkGrid>
 
        
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default WebOptimisationPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`