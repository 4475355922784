import PropTypes from "prop-types"
import React from "react"

const ContactForm = ({ pageName }) => (
  <form name="Contact Form" className="row" method="POST" data-netlify="true" action="/success">
    <input aria-label="Contact Form" type="hidden" name="form-name" value="Contact Form" />

    { pageName ? (
        <input type="hidden" value={pageName} />
      ) : null
    }
    <div className="column gm-bottom--1 small-12 medium-6">
      <label for="name">Name *
        <input aria-label="Name" type="text" name="name" />
      </label>
    </div>
    <div className="column gm-bottom--1 small-12 medium-6">
      <label for="email">Email *
        <input aria-label="Email" type="email" name="email" />
      </label>  
    </div>
    <div className="column gm-bottom--1 small-12">
      <label for="message">Message
        <textarea aria-label="Message" name="message" id="" rows="3"></textarea>
      </label>
    </div>
    <div className="column gm-top--1 small-12">
      <button className="button button--wider" type="submit">Send</button>
    </div>
  </form>
)

export default ContactForm