import { Link } from "gatsby"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Spacer from "../components/spacer"
import AOS from 'aos';
import 'aos/dist/aos.css';

const HeroAlt = ({ image, title, subtitle }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-in" data-aos-duration="900" className="row infinite-row hero hero--alt hero--overlap align-middle" style={{ backgroundImage:`url(${image})` }}>
      <div className="hero__content column small-12 medium-10 medium-offset-1 large-8 large-offset-2 text-center">
        <h1 data-aos="fade-up">{ title }</h1>
        <div className="row align-center">
          <div className="column small-12 medium-10 large-8">
            <p data-aos="fade-up" data-aos-delay="200" className="p--large gm-bottom--2">{ subtitle }</p>
          </div>
        </div>
        <Spacer space="4" />
      </div>
    </div>
  )
}

HeroAlt.propTypes = {
  image: PropTypes.string,
}

HeroAlt.defaultProps = {
  image: ``,
  title: ``,
  subtitle: ``,
}

export default HeroAlt