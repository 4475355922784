import React, { useEffect, Component } from "react"
// import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from "prop-types"
import AOS from 'aos';
import 'aos/dist/aos.css';

const ImageGridItem = ({ width, image, subtitle, delay, title, link, collab, collablink, showLink, video }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  let dely = 200;
  if(width == 7) {
    dely = 0;
  }

  return (
    <a target='_blank' rel="noopener noreferrer" href={ link } className={`column small-12 medium-${width} grid-item`}>
        <div data-aos-delay={dely} data-aos="fade-up" className="row collapse grid-item__background">
          { video ? (
            <div className="video-holder img-wrapper">
              <video autoPlay playsInline muted loop src={video}></video>
              <video className="hover-image" autoPlay playsInline muted loop src={video}></video>
            </div>
          ) : (
            <div className="img-wrapper">
              <img src={image} />
              <img src={image} className="hover-image" />
            </div>
          )}
          <div className="column relative">
            {/*<p className="subtitle">{ subtitle }</p>*/}
            <p className="grid-item__title"><strong>{ title }</strong></p>
            {/* collab ? 
              <a href={ collablink } target="_blank" rel="noopener noreferrer" className="show-for-large">
                <img src={`../os-${collab}.png`} width='175' alt={ title } style={{ position:'absolute', left:'1.5rem', bottom:'1.3rem' }} /> 
              </a>
            : '' */}
            { showLink == 'true' ? (
              <a href={ link } target='_blank' rel="noopener noreferrer" className='button grid-item__button'>View work</a>
            ): ''
            }
          </div>
        </div>
    </a>
  )
}

ImageGridItem.propTypes = {
  width: PropTypes.number,
  delay: PropTypes.number,
  image: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  collab: PropTypes.string,
  collablink: PropTypes.string,
}

ImageGridItem.defaultProps = {
  width: 6,
  delay:0,
  image: ``,
  subtitle: ``,
  title: ``,
  link: ``,
  collab: ``,
  collablink: ``,
}

export default ImageGridItem